/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-0: #131921;
  --clr-primary-1: #082f49;
  --clr-primary-2: #232f3f;
  --clr-primary-3: #075985;
  --clr-primary-4: #0369a1;
  /* primary/main color */
  --clr-primary-5: #0369a1;
  /* lighter shades of primary color */
  --clr-primary-6: #0ea5e9;
  --clr-primary-7: #38bdf8;
  --clr-primary-8: #7dd3fc;
  --clr-primary-9: #bae6fd;
  --clr-primary-10: #e0f2fe;
  /* darkest grey - used for headings */
  --clr-grey-1: #1c1917;
  --clr-grey-2: #292524;
  --clr-grey-3: #44403c;
  --clr-grey-4: #57534e;
  /* grey used for paragraphs */
  --clr-grey-5: #78716c;
  --clr-grey-6: #a8a29e;
  --clr-grey-7: #d6d3d1;
  --clr-grey-8: #e7e5e4;
  --clr-grey-9: #f5f5f4;
  --clr-grey-10: #fafaf9;
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-yellow-dark: #f08804;
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}

#root .react-color {
  color: #5ed3f3;
}
#root .css-color {
  color: #2194f0;
}
#root .js-color {
  color: #f7d800;
}
#root .node-color {
  color: #509941;
}
#root .html-color {
  color: #f75421;
}
#root .npm-color {
  color: #cf0000;
}

#root .linkedin-color {
  color: #0a66c2;
}

#root .mail-color {
  color: #0451a4;
}

#root .pdf-color {
  color: #990000;
}
#root .msword-color {
  color: #2a5495;
}
#root .tech-icon:hover {
  color: #000;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Supreme', sans-serif;
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
hr {
  border: none;
  border-top: 1px solid var(--clr-grey-8);
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Recia', serif;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-3);
}

.mr-20 {
  margin-right: 20px;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
}
/*  global classes */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

.mw-100 {
  max-width: 100vw;
  height: calc(100vh - 5rem);
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
.text-center {
  text-align: center;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: var(--clr-primary-5);
  animation: spinner 0.6s linear infinite;
}

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-primary-10);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 400;
  transition: var(--transition);
  font-size: 0.875rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
  border-color: transparent;
  text-align: center;
  line-height: 1;
  padding: 10px;
}

.btn.light {
  color: var(--clr-primary-1);
  background: var(--clr-primary-7);
  display: block;
  width: auto;
  margin: 0px auto;
  text-align: center;
  padding: 2rem;
  font-size: 2rem;
  font-weight: bold;
  border: 4px solid var(--clr-primary-7);
}

.btn.light:hover {
  background: transparent;
  color: var(--clr-primary-7);
}

.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-7);
}
.title {
  text-align: center;
}
.title .underline {
  width: 6rem;
  height: 0.25rem;
  background: #49a6e9;
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
}
.page-100 {
  min-height: calc(100vh - 10rem);
  padding: 5rem 0;
}
.page {
  min-height: calc(100vh - (20vh + 10rem));
  overflow: hidden;
}

.crossed-out {
  text-decoration: line-through;
  color: var(--clr-grey-3);
}

section.sc-hzhJZQ.guhiTb span.product-item {
  padding-right: 5px;
}

.modal-background {
  width: 100vw;
  height: 100vw;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  display: none;
}

.modal-background.active {
  display: block;
}

.slick-slider {
  max-width: 100vw;
}

@media (max-width: 567px) {
  section {
    padding-bottom: 0;
  }
}
